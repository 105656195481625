<template id="pqrs">
  <v-app id="container">
    <v-navigation-drawer permanent app>
      <v-list-item>
        <v-list-item-content style="text-align:center">
          <div style="width:360px; height:200px;display:flex; justify-content: center; align-items: center;">
            <a :href="backPqrs()">      
              <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%;"/>
            </a>
          </div>
          <v-list-item-title class="text-h6">
            Ventanilla Única
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ name }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        v-if="ok"
        dense
        nav
      >
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click="$router.replace({ name: item.link })"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container
        fluid
      >
        <router-view></router-view>
        <div class="alert alert-danger" role="alert" v-if="!ok">{{ message }}</div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      ok: false,
      message: '',
      name: 'NOMBRE',
      logo: '',
      items: [
        { title: 'Crear solicitud', icon: 'mdi-plus-circle-outline', link: 'NewPqrs' },
        { title: 'Buscar solicitud', icon: 'mdi-magnify', link: 'FindPqrs' },
      ],

    }
  },
  created(){
    //SD
    
    var regexParse = new RegExp('[a-z\-0-9]{2,63}\.[a-z\.]{2,5}$');
    var urlParts = regexParse.exec(window.location.hostname);
    var sd = window.location.hostname.replace(urlParts[0],'').slice(0, -1);
    
    // END SD

    //var sd = 'siged';
    
    if (sd) {
      //debugger;
      let uri = '/pqrs/sbs';
      this.axios.post(uri, { sd: sd })
      .then((response) => {
        this.$store.commit('setCompanyData', response.data.company);
        this.$store.commit('setCompany', response.data.company._id);
        this.$store.commit('setItem', response.data.pqrs);
        this.name = response.data.company.cmp_nmbre; 
        this.ok = true; 

        var path = './../repos/' + String(this.$store.state.company) + '/logos';
        this.downloadFileLogo(path, String(this.$store.state.companydata.cmp_logo));

        if (this.$route.params.action == 'rsp'){
          this.$router.replace({ name: 'FindPqrs' });
        }
      }).catch((err) => {
        this.message = '¡Error al buscar los valores predefinidos para la PQRS! ' + err;
      });
    }
  },
  methods: {
    downloadFileLogo(path, filename) {
      this.axios({
        url: '/api/files/download',
        method: 'POST',
        data: {
          'path': path,
          'filename': filename
        },
        responseType: 'blob'
      }).then((response) => {
        this.logo = window.URL.createObjectURL(new Blob([response.data]));
        this.$store.commit('setLogo', this.logo);
      });
    },
    backPqrs(){
      var url = this.$store.state.item.pqr_urlpqrs;
      if (url && url != ''){
        return url;
      }
      else{
        return '#';
      }
    }
  }  
}
</script>